import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  paragraph: {
    fontSize: '18px',
    lineHeight: '30px'
  }
}));

const Location = props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography component="p" className={classes.paragraph}>
        Apartmani Mima su smešteni u samom centru Sokobanje, na udaljenosti od svega par metara od glavnog šetališta.
      </Typography>

      <Typography component="p" className={classes.paragraph}>
        Adresa: ulica Mitropolita Mihaila , ulica 27. marta Sokobanja;
      </Typography>
    </React.Fragment>
  )
}

export default Location;