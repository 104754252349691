import React from "react"

import SEO from "../components/seo"
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BackToTop from "../components/BackToTop"
import AboutUs from './../components/AboutUs';
import Gallery from './../components/Gallery';
import AboutPlace from './../components/AboutPlace';
import Location from './../components/Location';
import Contact from './../components/Contact';
import { ThemeProvider } from '@material-ui/styles';
import themeConfig from './../theme';
import './../main.css';
import ImagesCarousel from "../components/ImagesCarousel";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    clear: 'both',
    marginBottom: '20px',
    display: 'inline-block',
    width: '100%'
  },
  paperHeader: {
    marginBottom: '40px',
    paddingBottom: '20px',
    fontFamily: 'Dancing Script,cursive',
    fontSize: '30px',
    borderBottom: '1px solid rgba(226, 210, 210, 0.87)'
  },
  paragraph: {
    fontSize: '18px',
    lineHeight: '30px'
  }
}));

const IndexPage = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={themeConfig}>
      <BackToTop>
        <SEO title="Sokobanja Apartmani" />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography className={classes.paperHeader} variant="h5" component="h3">
                O Nama
              </Typography>
              <Typography className={classes.paragraph} component="p">
                <AboutUs />
              </Typography>
            </Paper>
  
            <Paper className={classes.paper}>
              <Typography className={classes.paperHeader} variant="h5" component="h3">
                Galerija
              </Typography>
              <Gallery />
            </Paper>

            <Paper className={classes.paper}>
              <Typography className={classes.paperHeader} variant="h5" component="h3">
                O Sokobanji
              </Typography>
              <Typography className={classes.paragraph} component="p">
                <AboutPlace />
              </Typography>
              <div style={{
                margin: '0 auto',
                maxWidth: '700px'
              }}>
                <ImagesCarousel />
              </div>
            </Paper>
            <Paper className={classes.paper}>
              <Typography className={classes.paperHeader} variant="h5" component="h3">
                Lokacija
              </Typography>
              <Typography className={classes.paragraph} component="p">
                <Location />
              </Typography>
            </Paper>
            <Paper className={classes.paper}>
              <Typography className={classes.paperHeader} variant="h5" component="h3">
                Kontakt
              </Typography>
              <Typography className={classes.paragraph} component="p">
                <Contact />
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </BackToTop>
    </ThemeProvider>
  )
}

export default IndexPage
