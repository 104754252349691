import React from 'react';

const AboutPlace = props => {
  return (
    <React.Fragment>
      Sokobanja je banjsko turističko mesto, smeštena je u jugoistočnoj Srbiji u podnožju planine Ozren. Okružuju je četiri planine: Rtanj, Ozren, Devica i Bukovik. Kroz Sokobanju protiče reka Moravica koja izvire ispod planine Device i od nje je napravljeno veštačko jezero, koje nosi naziv Bovansko jezero. Sokobanja poseduje prelepa izletišta kao što su: Lepterija, Kalinovica, izvor reke Moravice (Vrelo), Sokograd (stari grad nastao u periodu Vizantijskog vladara Justinijana). Pored napomenutih izletišta, Sokobanja poseduje i akva park. Ona ima izuzetno čist vazduh i termalne vode, pa je idealno mesto za odmor, lečenje plućnih bolesti, reumatskih oboljenja.Sokobanja je pogodna kako za starije tako i za mlade . U samom centru se nalazi divno šetalište, okruženo parkom i brojnim kafićima. Nikako ne smemo izostaviti znamenitosti od velike istorijske vrednosti- Tursko kupatilo i Milošev konak, sagrađeni za vreme Turaka. U Turskom kupatilu je snimljena jedna scena poznatog filma "Zona Zamfirova". Takođe možete uživati i u ukusnim specijalitetima banjskih restorana. Sokobanja nije zapostavila ni mlađi deo populacije, kada su u pitanju noćni izlasci, pa su tako dostupni i brojni klubovi i kafane.
    </React.Fragment>
  )
}

export default AboutPlace;