import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import Logo from './../images/logo.svg';
import ParallaxImg from './../images/Apartmani-Mima-Sokobanja-3.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  logo: {
    maxWidth: '50px'
  },
  logoName: {
    fontFamily: 'Dancing Script,cursive',
    fontSize: '30px',
    marginLeft: '20px'
  },
  parallax: {
    backgroundImage: `url(${ParallaxImg})`,
    minHeight: '600px',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
}));

const ScrollTop = props => {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

const BackToTop = props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <img className={classes.logo} src={Logo} alt="Apartmani Mima Logo" />
          <span className={classes.logoName}>
            Apartmani Mima
          </span>
          <span style={{
            fontSize: '12px',
            marginTop: '10px',
            marginLeft: '7px'
          }}>
            Sokobanja
          </span>
        </Toolbar>
      </AppBar>
      <div className={classes.parallax}></div>
      <div id="back-to-top-anchor" />
      <Container>
        <Box my={2}>
          {props.children}
        </Box>
      </Container>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}

export default BackToTop;