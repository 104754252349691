import React from 'react';
import GridGallery from 'react-grid-gallery';
import ImagesService from './../components/ImagesService';

const Gallery = props => {
  return (
    <React.Fragment>
      <GridGallery
        enableImageSelection={false}
        images={ImagesService.getAll()} />
    </React.Fragment>
  )
}

export default Gallery;