import Image1 from './../images/Apartmani-Mima-Sokobanja-1.jpg';
import Image2 from './../images/Apartmani-Mima-Sokobanja-2.jpg';
import Image3 from './../images/Apartmani-Mima-Sokobanja-3.jpg';
import Image4 from './../images/Apartmani-Mima-Sokobanja-4.jpg';
import Image5 from './../images/Apartmani-Mima-Sokobanja-6.jpg';
import Image6 from './../images/Apartmani-Mima-Sokobanja-1.jpg';
import Image7 from './../images/Apartmani-Mima-Sokobanja-7.jpg';
import Image8 from './../images/Apartmani-Mima-Sokobanja-8.jpg';
import Image9 from './../images/Apartmani-Mima-Sokobanja-9.jpg';
import Image10 from './../images/Apartmani-Mima-Sokobanja-10.jpg';
import Image11 from './../images/Apartmani-Mima-Sokobanja-11.jpg';
import Image12 from './../images/Apartmani-Mima-Sokobanja-12.jpg';
import Image13 from './../images/Apartmani-Mima-Sokobanja-13.jpg';
import Image14 from './../images/Apartmani-Mima-Sokobanja-14.jpg';
import Image15 from './../images/Apartmani-Mima-Sokobanja-15.jpg';
import Image16 from './../images/Apartmani-Mima-Sokobanja-16.jpg';
import Image17 from './../images/Apartmani-Mima-Sokobanja-17.jpg';
import Image18 from './../images/Apartmani-Mima-Sokobanja-18.jpg';
import Image19 from './../images/Apartmani-Mima-Sokobanja-19.jpg';
import Image20 from './../images/Apartmani-Mima-Sokobanja-20.jpg';
import Image21 from './../images/Apartmani-Mima-Sokobanja-21.jpg';
import Image22 from './../images/Apartmani-Mima-Sokobanja-22.jpg';
import Image23 from './../images/Apartmani-Mima-Sokobanja-23.jpg';
import Image24 from './../images/Apartmani-Mima-Sokobanja-24.jpg';
import Image25 from './../images/Apartmani-Mima-Sokobanja-25.jpg';
import Image26 from './../images/Apartmani-Mima-Sokobanja-26.jpg';
import Image27 from './../images/Apartmani-Mima-Sokobanja-27.jpg';

const ImagesService = {
  getAll () {
    return [
      {
        src: Image1,
        thumbnail: Image1,
        alt: 'Slika apartmana Sokobanja 1'
      },
      {
        src: Image2,
        thumbnail: Image2,
        alt: 'Slika apartmana Sokobanja 2'
      },
      {
        src: Image3,
        thumbnail: Image3,
        alt: 'Slika apartmana Sokobanja 3'
      },
      {
        src: Image4,
        thumbnail: Image4,
        alt: 'Slika apartmana Sokobanja 4'
      },
      {
        src: Image5,
        thumbnail: Image5,
        alt: 'Slika apartmana Sokobanja 5'
      },
      {
        src: Image6,
        thumbnail: Image6,
        alt: 'Apartmani Sokobanja 6'
      },
      {
        src: Image7,
        thumbnail: Image7,
        alt: 'Apartmani Sokobanja 7'
      },
      {
        src: Image8,
        thumbnail: Image8,
        alt: 'Apartmani Sokobanja 8'
      },
      {
        src: Image9,
        thumbnail: Image9,
        alt: 'Apartmani Sokobanja 9'
      },
      {
        src: Image10,
        thumbnail: Image10,
        alt: 'Sobe Sokobanja 10'
      },
      {
        src: Image11,
        thumbnail: Image11,
        alt: 'Sobe Sokobanja 11'
      },
      {
        src: Image12,
        thumbnail: Image12,
        alt: 'Sobe Sokobanja 12'
      },
      {
        src: Image13,
        thumbnail: Image13,
        alt: 'Sobe Sokobanja 13'
      },
      {
        src: Image14,
        thumbnail: Image14,
        alt: 'Sobe Sokobanja 14'
      },
      {
        src: Image15,
        thumbnail: Image15,
        alt: 'Sobe Sokobanja 15'
      },
      {
        src: Image16,
        thumbnail: Image16,
        alt: 'Apartmani Sokobanja 16'
      },
      {
        src: Image17,
        thumbnail: Image17,
        alt: 'Apartmani Sokobanja 17'
      },
      {
        src: Image18,
        thumbnail: Image18,
        alt: 'Apartmani Sokobanja 18'
      },
      {
        src: Image19,
        thumbnail: Image19,
        alt: 'Apartmani Sokobanja 19'
      },
      {
        src: Image20,
        thumbnail: Image20,
        alt: 'Apartmani Sokobanja 20'
      },
      {
        src: Image21,
        thumbnail: Image21,
        alt: 'Apartmani Sokobanja 21'
      },
      {
        src: Image22,
        thumbnail: Image22,
        alt: 'Apartmani Sokobanja 22'
      },
      {
        src: Image23,
        thumbnail: Image23,
        alt: 'Smestaj Sokobanja 23'
      },
      {
        src: Image24,
        thumbnail: Image24,
        alt: 'Nocenje Sokobanja 24'
      },
      {
        src: Image25,
        thumbnail: Image25,
        alt: 'Nocenje Sokobanja 25'
      },
      {
        src: Image26,
        thumbnail: Image26,
        alt: 'Prenociste Sokobanja 26'
      },
      {
        src: Image27,
        thumbnail: Image27,
        alt: 'Prenociste Sokobanja 27'
      },
    ]
  }
}

export default ImagesService;