import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  paragraph: {
    fontSize: '18px',
    lineHeight: '30px'
  }
}));

const Contact = props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography component="p" className={classes.paragraph}>
        Telefon: 064 5733621, 018/834-892
      </Typography>
      <Typography component="p" className={classes.paragraph}>
        <a href="https://sr-rs.facebook.com/mimaapartmanisokobanja" target="_blank">Facebook</a>
      </Typography>
    </React.Fragment>
  )
}

export default Contact;