import React from 'react';
import Img1 from './../images/slider/Apartmani-Mima-Sokobanja-1.jpg';
import Img2 from './../images/slider/Apartmani-Mima-Sokobanja-2.jpg';
import Img3 from './../images/slider/Apartmani-Mima-Sokobanja-3.jpg';
import Img4 from './../images/slider/Apartmani-Mima-Sokobanja-4.jpg';
import Img5 from './../images/slider/Apartmani-Mima-Sokobanja-5.jpg';
import Img6 from './../images/slider/Apartmani-Mima-Sokobanja-6.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const ImagesCarousel = props => {
  return (
    <Carousel>
        <div>
          <img src={Img1} alt="Sokobanja fotografije" />
        </div>
        <div>
          <img src={Img2} alt="Sokobanja fotografije" />
        </div>
        <div>
          <img src={Img3} alt="Sokobanja fotografije" />
        </div>
        <div>
          <img src={Img4} alt="Sokobanja fotografije" />
        </div>
        <div>
          <img src={Img5} alt="Sokobanja fotografije" />
        </div>
        <div>
          <img src={Img6} alt="Sokobanja fotografije" />
        </div>
    </Carousel>
  )
}

export default ImagesCarousel;